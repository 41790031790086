html, body {
  -webkit-font-smoothing: subpixel-antialiased;
}
.select-style select option
{
   text-transform:capitalize
} 

a {
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  transition: all 300ms ease-out;

  &:hover {
    text-decoration: none;
  }

  &.logo {
    display: inline-block;
    width: 172px;
    height: 56px;
    background-image: url("../img/logo-phenix.png");
    background-repeat: no-repeat;
    background-size: 172px 56px;
    margin: 20px 20px 20px 0;
    float: left;

    span {
      display: none;
    }
  }

  &.burger-icon {
    .hidden-md;
    .hidden-lg;
    .pull-right();
    display: inline-block;
    margin: 35px 0 0 10px;
    width: 33px;
    height: 26px;
    background-image: url("../img/icon-mobile.png");
    background-repeat: no-repeat;
    background-size: 33px 26px;

    span {
      display: none;
    }
  }
}

ul {

  &.menu {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a {
        line-height: 1em;
        color: @text-color;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          a {
            line-height: 1em;
          }
        }
      }
    }
  }

  &.func-menu {
    .hidden-xs;
    .hidden-sm;
    .hidden-md;
    display: inline-block;
    float: left;
    margin: 39px 0 0 0;

    li {
      float: left;

      a {
        font-size: 12px;
        font-weight: 300;
        color: #606060;
        padding: 0 10px;
        border-right: 1px solid #ccc;

        &:hover {
          color: @text-color;
        }
      }

      &:last-child {
        a {
          border-right: none;
        }
      }
    }
  }

  &.main-menu {
    border-top: 2px solid #000;
    padding: 0;
    margin: 3px 0;
    z-index: 1001;
    > li {
      display: none;
      position: relative;

      &:first-child {
        margin-top: 16px;
      }

      a {
        -webkit-transition: all 0ms;
        -moz-transition: all 0ms;
        transition: all 0ms;
        display: block;
        font-family: @font-family-serif;
        font-size: 24px;
        font-style: italic;
        color: #606060;
        padding: 8px 16px;

        &:hover {
          color: #000;
          text-decoration: none;
          background-color: #E5E5E5;
        }
      }

      ul {
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1010;
        background-color: #E5E5E5;
        width: 50%;

        li {
          display: none;
        }
      }

      &:hover {
        ul {
          display: block;
        }
      }
    }
  }

  &.crumbs {
    li {
      float: left;
      color: #656565;
      padding: 0 11px 0 0;

      &:first-child {
        .hidden-xs;
      }

      &:before {
        .fontello;
        margin-right: 4px;
        text-align: left;
        content: '\e80a';
      }

      a {
        color: #656565;

        &:hover {
          color: @text-color;
        }
      }
    }
  }

  &.navi {
    .hidden-xs;
    .hidden-sm;
    .pull-right();

    li {
      float: left;
      color: #656565;
      margin: 0 0 0 32px;

      a {
        color: #656565;

        &:hover {
          color: @text-color;
        }

        &.previous {
          &:before {
            .fontello;
            margin-right: 4px;
            text-align: left;
            content: '\e809';
          }
        }

        &.next {
          &:after {
            .fontello;
            margin-right: 0;
            margin-left: 4px;
            text-align: right;
            content: '\e80a';
          }
        }
      }
    }
  }

  &.filters {
    > li {
      padding: 16px 0;
      border-bottom: 1px solid #D3D3D3;
      color: #322f2f;

      &:last-child {
        border: none;
      }

      &:before {
        .fontello;
        text-align: left;
        content: '\e80a';
      }

      a {
        font-size: 13px;
        color: #322f2f;

        &:hover {
          color: @text-color;
        }

        &[aria-expanded="true"] {
          font-weight: 700;
        }
      }

      label {
        padding: 0 0 0 8px;
        font-weight: normal;
      }

      ul {
        margin: 12px 0 0 18px;

        li {
          input[type="checkbox"] {
            margin-left: 16px;

            &:checked + label {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  &.products {
    li {
      position:relative;
      a {
        display: block;
        color: #656565;
        overflow: hidden;

        &:hover {
          .box-shadow(0 0 4px rgba(0,0,0,.25));
          color: @text-color;
        }
      }
      div.product_item_new
      {
        width:40px;
        height:40px;
        border-radius:20px;
        background:#000000;
        position:absolute;
        right:20px;
        top:20px;
        z-index:100;
        color:#fff;
        line-height:40px;
        font-size:10px!important;
        text-align:center;
        -ms-transform: rotate(-20deg);
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);

      }
      div.image {
        display: block;
        height: 300px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      p {
        &.details {
          display: inline-block;
          .pull-left();
          padding-left: 10px;
        }
        &.price {
          display: inline-block;
          .pull-right();
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
}

form {

  button {
    border: none;
    background-color: #B8B8B8;
    font-size: 10px;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 2px;
    -webkit-font-smoothing: subpixel-antialiased;
    color: #fff;
    padding: 12px;

    &[type=submit] {
      background-color: #000;
    }
  }

  &.bordered {
    display: inline-block;
    height: 48px;
    border: 1px solid #E4E4E4;
    padding: 2px;

    input {
      border: none;
      outline: none;
      height: 40px;
      /* Firefox */
      width: -moz-calc(100% - 100px);
      /* WebKit */
      width: -webkit-calc(100% - 100px);
      /* Opera */
      width: -o-calc(100% - 100px);
      /* Standard */
      width: calc(100% - 100px);

      &:focus {
        outline: none;
      }
    }

    button {
      background-color: #CFCFCF;
      padding: 16px;
      letter-spacing: 0;
      .pull-right();
    }
  }

  &.search {
    .hidden-xs;
    .hidden-sm;
    .pull-right();
    margin: 24px 10px 0 0;

    input {
      background: url("../img/mag.png") no-repeat 16px 14px;
      padding-left: 40px;
    }
  }

  .select-style {
    display: inline-block;
    border: 1px solid #ccc;
    width: 180px;
    border-radius: 0;
    overflow: hidden;
    background: #fafafa url("data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==") no-repeat 96% 50%;
  }

  .select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
  }

  .select-style select:focus {
    outline: none;
  }
}

header.main {
  .bar {
    .hidden-xs;
    .hidden-sm;
    background-color: #000;
    color: #fff;
    padding: 6px 0;

    ul {
      margin: 0;
      padding: 0;
      .flex-display;
      .justify-content(space-between);

      li {
        list-style: none;
        font-size: 12px;

        &:before {
          .fontello;
          font-size: 120%;
          content: '\e800';
        }
      }
    }
  }

  nav.main {
    border-top: 1px solid #D3D3D3;
  }

  .cart {
    .hidden-xs;
    .pull-right();
    display: inline-block;
    height: 48px;
    background: url("../img/bag.png") no-repeat right 8px;
    border: 1px solid #E4E4E4;
    margin: 24px 14px 0 0;
    padding: 17px 54px 17px 17px;
    font-size: 12px;
    line-height: 1em;
    text-transform: uppercase;
    color: #A9A9A9;
  }
}

footer.main {
  padding-top: 14px;
  h3 {
    font-family: @font-family-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #454545;
    margin: 34px 0 40px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        &:before {
          .fontello;
          margin-right: 0.5em;
          content: '\e806';
        }
    }
    &.menu {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }

  p {
    margin: 0 0 16px;
  }

  img {
    &.padded {
      margin: 0 6px 16px 0;
    }
  }

  div.bordered {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    padding: 2px 0;
  }
}

.product-detail {
  div.bordered {
    border-bottom: 1px solid #D3D3D3;
    padding: 24px 0 24px 28px;
    background-image: url("../img/arr-right.png");
    background-repeat: no-repeat;
    background-position: 4px 27px;
  }

  h1 {
    font-size: 20px;
    color: #322f2f;
    text-transform: uppercase;
    line-height: 1em;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;

    &.sku {
      display: inline-block;
      font-size: 11px;
      font-style: italic;
      color: #969696;
    }

    &.price {
      font-family: @font-family-serif;
      font-size: 20px;
      font-weight: 700;
      color: #322f2f;
      display: inline-block;
      .pull-right();
      text-align: right;
      position: relative;
      top: -12px;
    }
  }

  form {
    label {
      width: 20%;
      margin: 0;
      padding: 6px 0 0 0;
      float: left;
    }

    button {
      margin: 18px 0 0 0;
      width: 49.9%;
      .pull-right();

      &[type=submit] {
        .pull-left();
      }
    }

    div.bordered {
      padding-top: 18px;
    }
  }
}

.page {
  a {
    color: @text-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: #606060;
    }
  }
  .top-bar {
    padding: 16px 0;
  }
}

.related {
  padding-top: 60px;
  padding-bottom: 60px;
  h3 {
    font-size: 15px;
    text-transform: uppercase;
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: 12px;
  }
}

#carousel-home {
 
  margin-bottom: 40px;
  .carousel-caption {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    text-shadow: none;
    h1 {
      font-family: @font-family-serif;
      font-style: italic;
      color: #000;
      font-size: 36px;
      line-height: 1.2em;
      margin-bottom: 28px;
    }
    a.read-more {
      color: #000;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 1em;
      border: 1px solid #000;
      padding: 10px 24px;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .carousel-control {
    display: none;
    background-image: none;

    &:hover {
      background-image: none;
    }
  }
}

#carousel-product {
  padding-top: 25px;

  .carousel-inner {
    .item {
      img {
        margin: auto;
      }
    }
  }

  .carousel-control {
    background-image: none;
    color: #656565;
    text-shadow: none;

    .icon-left-open-big {
      position: absolute;
      left: -5px;
      top: 50%;
      margin-top: -9px;
      width: 10px;
      height: 18px;
    }

    .icon-right-open-big {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -9px;
      width: 10px;
      height: 18px;
    }
  }
}

.slick-logos {
  border-top: 1px solid #D3D3D3;
  border-bottom: 1px solid #D3D3D3;
  padding: 30px 0;
  a {
    margin: 0 45px;
  }
}

.top-bar {
  padding: 24px 0;
  border-bottom: 1px solid #D3D3D3;
  overflow: hidden;
}

.block {
  .hidden-xs;
  .hidden-sm;
  background-color: #F4F4F4;
  padding: 28px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      font-size: 12px;
      margin: 0 0 12px 20px;
      text-indent: -1.6em;
      //margin: 0.5em 2em;

      &:before {
        //.fontello;
        font-family: "fontello";
        content: '\e800';
        float: left;
        width: 1.6em;
        text-align: left;
        //font-size: 120%;
      }
    }
  }

  &.barred {
    .visible-xs-block;
    .visible-sm-block;
    .visible-md-block;
    .visible-lg-block;
    padding: 6px 28px;
    ul {
      .flex-display;
      .justify-content(space-between);

      li {
        margin: 0;
      }
    }
  }
}

.fontello {

  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 1em;
  text-align: center;
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  font-size: 100%;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}