/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
  ul {
    &.main-menu {
      li {
        a {
          font-size: 36px;
          padding: 14px 0 14px 60px;
        }
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {

  html, body {
    height: 100%;
    /* The html and body elements cannot have any padding or margin. */
  }

  /* Wrapper for page content to push down footer */
  #wrapper {
    min-height: 100%;
    height: auto;
    /* Negative indent footer by its height */
    margin: 0 auto -420px;
    /* Pad bottom by footer height */
    padding: 0 0 420px;
  }

  /* Set the fixed height of the footer here */
  footer.main {
    height: 420px;
  }

  div.container.content {
    padding-top: 217px;/*203px*/
  }

  header.main {
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    position: fixed;
    width: 100%;
    z-index: 9000;
    background-color: #fff;

    .cart {
      margin-right: 0;
    }

    &.sticky {
      top: -131px;
    }
  }

  nav.main {
    border-bottom: 1px solid #D3D3D3;
  }

  ul {
    &.main-menu {
      border-bottom: 2px solid #000;
      padding: 0;
      .flex-display;
      .justify-content(space-between);

      > li {
        display: inline-block !important;
        margin: 6px 0;

        &:first-child {
          margin-top: 6px;
          margin-left: 0 !important;
          a {
            padding-left: 0 !important;
          }
        }

        &:last-child {
          margin-right: 0 !important;
          a {
            padding-right: 0 !important;
          }

          &.has-children {
            > a {
              border-right: 1px solid #fff;
            }

            ul {
              left: auto;
              right: 0;
            }
          }
        }

        &.has-children {

          &:hover {
            > a {
              border: 1px solid #D3D3D3;
              border-bottom: none;
              padding: 19px 9px 20px 9px;
              background-color: #fff;
            }
          }
        }

        a {
          position: relative;
          z-index: 1030;
          font-size: 14px;
          font-style: normal;
          text-transform: uppercase;
          color: #818181;
          padding: 20px 10px;
          border-bottom: none;
          box-sizing: border-box;

          &:hover, &.active {
            background-color: #fff;
            color: #000;
            text-decoration: none;
          }
        }

        ul {
          display: none;
          position: absolute;
          top: 53px;
          left: 0;
          width: 200px;
          padding: 16px 24px;
          background-color: #fff;
          border: 1px solid #D3D3D3;
          z-index: 1020;

          li {
            display: block !important;
            padding: 0;
            border: none;
            border-bottom: 1px dotted #D3D3D3;

            &:last-child {
              border-bottom: none;
            }

            a {
              padding: 8px 0;

              &:before {
                .fontello;
                text-align: left;
                margin-right: 2px;
                content: '\e806';
              }
            }
          }
        }
      }
    }
  }

  #carousel-home {
    margin-bottom: 0;
    .carousel-caption {
      position: absolute;
      left: 0;
      right: 0;
      top: 32%;
      h1 {
        color: #fff;
        font-size: 94px;
        margin-bottom: 68px;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
      }
      a.read-more {
        color: #fff;
        border: 1px solid #fff;
      }
    }
    .carousel-control {
      display: block;
      z-index: 1000;
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {

  ul {
    &.main-menu {

      > li {

        &.has-children {
          > a {
            &:after {
              .fontello;
              margin-right: 0 !important;
              text-align: right;
              line-height: 0.5em;
              content: '\e804';
            }
          }

          &:hover {
            > a {
              padding: 19px 13px 20px 13px;
            }
          }
        }

        a {
          padding: 20px 14px;
        }
      }

    }
  }

}