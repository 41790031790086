html {
	&.no-flexbox {

		header.main {
		  .bar {
        text-align: center;
		    ul {
          display: inline-block;
		      li {
            float: left;
            margin-right: 40px;
		      }
		    }
		  }
		}

    .block {
      &.barred {
        text-align: center;
        ul {
          display: inline-block;
          li {
            float: left;
            margin-right: 40px;
          }
        }
      }
    }

	}
}