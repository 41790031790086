.tile {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center top;
  height: 600px;
  margin: 0 0 20px 0;

  svg {
    position: absolute;
    top: 200px;
    width: 100%;
  }

  .body {
    position: absolute;
    bottom: 0;

    .inner {
      padding: 0 30px 40px 30px;
    }

    h2 {
      font-family: @font-family-serif;
      font-size: 34px;
      letter-spacing: 2px;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      line-height: 1em;
      margin: 0 0 14px 0;
      -webkit-font-smoothing: antialiased;
    }

    h3 {
      font-family: @font-family-serif;
      font-size: 15px;
      font-style: italic;
      color: #616161;
      margin: 0 0 20px 0;
      -webkit-font-smoothing: antialiased;
    }

    p {
      color: #fff;
      margin: 0 0 20px 0;
      -webkit-font-smoothing: antialiased;
    }

    a.read-more {
      display: inline-block;
      color: #fff;
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1em;
      border: 1px solid #fff;
      padding: 10px 24px;
      -webkit-font-smoothing: antialiased;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
  .tile {
    width: calc(50% - 10px);
    float: left;

    &.odd {
      margin: 0 10px 20px 0;
    }

    &.even {
      margin: 0 0 20px 10px;
    }

    .body {
      .inner {
        padding: 0 30px;
        display: table-cell;
        height: 310px;
        vertical-align: middle;
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
  .tile {
    width: 100%;
    float: left;
    background-position: right top;
    height: 320px;

    svg {
      width: 330px;
      height: 460px;
    }

    .body {
      top: 0;
      width: 370px;

      .inner {
        height: 320px;
      }
    }

    &.odd {
      margin: 0;

      svg {
        left: 60px;
        top: -70px;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    &.even {
      margin: 0;
      background-position: left top;

      svg {
        right: 60px;
        top: -70px;
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      .body {
        right: 0;
      }
    }
  }
}